import PropTypes from 'prop-types';
import { withNavigationContext } from 'InPlayOverview/helpers/navigation';
import { t } from 'bv-i18n';
import { Link } from 'bv-components';

export const SeeMoreMarketsComponent = ({ eventHref }) => (
  <Link to={eventHref} className="bvs-button-multi-sport more-markets">
    {t('javascript.in_play.see_more_markets')}
  </Link>
);

SeeMoreMarketsComponent.propTypes = {
  eventHref: PropTypes.string.isRequired,
};

export default withNavigationContext(SeeMoreMarketsComponent);
