import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { linkLocalizer } from 'bv-helpers/locale';
import { t } from 'bv-i18n';

export const NoEventsComponent = ({ pgatePath, treeType }) => {
  let btnText = '';
  let eventText = '';
  let error = false;

  if (pgatePath === null) {
    error = true;
    eventText = t('javascript.in_play.tree_error');
  } else if (treeType === 'in_play') {
    eventText = t('javascript.in_play.no_live_events');
    btnText = t('javascript.in_play.view_upcoming');
  } else if (treeType === 'upcoming') {
    eventText = t('javascript.in_play.no_upcoming_events');
    btnText = t('javascript.in_play.view_live');
  }

  return (
    <div className="no-events-message">
      <div className="no-events-message__icon" />
      <div className="no-events-message__text">
        <p>{eventText}</p>
        {!error && (
          <a
            className="bvs-button is-secondary inplay-noevents-cta"
            href={linkLocalizer(
              `/live/overview${treeType === 'in_play' ? '/upcoming' : ''}`,
            )}
          >
            {btnText}
          </a>
        )}
      </div>
    </div>
  );
};

NoEventsComponent.propTypes = {
  treeType: PropTypes.string.isRequired,
  pgatePath: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  sports: state.inplay.sports,
  pgatePath: state.inplay.pgatePath,
  treeType: state.inplay.treeType,
});

export default connect(mapStateToProps)(NoEventsComponent);
