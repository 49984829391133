export default (markets, marketType, period) => {
  let market = null;
  const numericalInputsOnly = /^[0-9, ]+$/.test(period.dimensionParams);

  const marketTypeMarkets = markets.filter((m) => (
    marketType.marketTypeDimensions.includes(m.mtdim)
      && period.periodDimensions.includes(m.pdim)
      && (!numericalInputsOnly || period.dimensionParams.toString() === (m.pinputs || []).join(','))
  ));

  if (marketTypeMarkets.length >= 1) {
    if (period.config.filter === '' || period.config.filter === 'ALL') {
      const filteredMarkets = marketTypeMarkets.filter((m) => m.o && m.o.length > 0);
      // try finding an open market first, sometimes them most balanced market is suspended
      // but there's still an open market with higher MBL
      const [filteredSortedMarket] = filteredMarkets.filter((m) => m.st === 1)
        .sort((m1, m2) => (m1.mbl - m2.mbl));
      market = filteredSortedMarket;
      if (!market) {
        // if it fails ignore status
        const [sortedMarket] = filteredMarkets.sort((m1, m2) => (m1.mbl - m2.mbl));
        market = sortedMarket;
      }
    }
    if (period.config.filter === 'NEXT') {
      market = marketTypeMarkets.find((m) => m.next === true || m.next === 1);
    }
    if (period.config.filter === 'CURRENT') {
      market = marketTypeMarkets.find((m) => m.current === true || m.current === 1);
    }
  }

  return market;
};
