import PropTypes from 'prop-types';
import { ToggleIcon } from 'bv-components';

const CompetitionHeader = ({
  toggle,
  folded,
  description,
}) => (
  <div className="bvs-card-header bvs-link" onClick={toggle}>
    <h3 className="bvs-header market-header flex--grow">{description}</h3>
    <div className="flex--no-shrink">
      <ToggleIcon light solid folded={folded} toggleFolded={toggle} />
    </div>
  </div>
);

CompetitionHeader.propTypes = {
  description: PropTypes.isRequired,
  toggle: PropTypes.func.isRequired,
  folded: PropTypes.bool.isRequired,
};

export default CompetitionHeader;
