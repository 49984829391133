import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { addMiddleware } from 'redux-dynamic-middlewares';
import App from './app.jsx';
import reducer, { inPlayChangeTreeType, inPlayOverviewInit } from '../duck';
import inPlayOverviewMiddleware from '../middleware';

const { store, addReducers } = window.reduxState;
const { VCSTATE } = window;

addReducers({
  inplay: reducer,
});

addMiddleware(
  inPlayOverviewMiddleware,
);

const AppRedux = ({ treeType, isGroup, widgetSport }) => {
  const { sport_id: sportId = 'overview' } = useParams();
  const parsedSportId = !Number.isNaN(Number(sportId)) ? parseInt(sportId, 10) : sportId;
  const widgetSportId = widgetSport > 0 ? widgetSport : 'mini_inplay';
  const widget = !!widgetSport;
  const [addedToRedux, setAddedToRedux] = useState(false);

  // TODO: Same as other components, locale, brand_id, etc can't be changed without a refresh
  // Let's remove them from configs and do them read only
  // Get sport as group info from the backend, we are adding 1 extra request here
  // Will not change it yet as inplay is not only used for the component but the inplay page

  const options = {
    locale: VCSTATE.LOCALE,
    brandId: VCSTATE.SITE_BRAND,
    sportId: widget ? widgetSportId : parsedSportId,
    treeType: treeType || 'in-play',
    isGroup,
    channel: 'mobile',
    widget,
  };

  // TODO: A redux component is supposed to pass redux props to children
  // Not to dispatch things
  // This is to be redone, store used directly and not via mapStateToProps or useSelector
  // Doing this just a fix because it's a live issue
  // Before useMemo was used to ensure it was done when the inner dispatches were happening
  //
  // Proper fix:
  // - Do not abuse of redux, there is nothing to be added to redux here.
  // - locale, brandId and channel cannot change.
  // - sportId, treeType to be obtained from URL or context
  // - It was already weird to be initialising inside an useMemo, and removing from other component
  //
  // Gardening needed here.
  useEffect(() => {
    store.dispatch(inPlayOverviewInit(options));
    setAddedToRedux(true);
  }, []);

  useEffect(() => {
    if (addedToRedux) {
      window.scrollTo(0, 0);
      store.dispatch(inPlayChangeTreeType(treeType, parsedSportId));
    }
  }, [treeType]);

  if (!addedToRedux) return null;
  return <App {...options} />;
};

AppRedux.propTypes = {
  widgetSport: PropTypes.number,
  treeType: PropTypes.string,
  isGroup: PropTypes.bool,
};

AppRedux.defaultProps = {
  widgetSport: undefined,
  treeType: 'in_play',
  isGroup: false,
};

export default AppRedux;
