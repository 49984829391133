import { useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Icon, ButtonCard, Skeleton, Spinner,
} from 'bv-components';
import { t } from 'bv-i18n';
import { inPlayOverviewSportsTreeFetch, inPlayOverviewReset as inPlayOverviewResetAction } from 'InPlayOverview/duck';
import { Redux as SuspensionReasonsRedux } from 'sportsbook-suspension-reasons';
import AppHeader from 'InPlayOverview/components/app_header';
import NoEvents from 'InPlayOverview/components/competitions/no_events';
import GoToInPlay from 'InPlayOverview/components/competitions/go_to_inplay';
import SportSelector from './sport_selector/sport_selector';
import Competitions from './competitions/competitions';

const App = ({
  fetching, fetchInPlayOverview, fetchSuspensionReasons, inPlayOverviewReset, isGroup, loaded,
  locale, selectedSportId, slider, sports, totalNumberOfEvents, treeType, widget,
}) => {
  const rootNode = useRef(null);

  useEffect(() => {
    fetchInPlayOverview(treeType);
  }, [treeType]);

  useEffect(() => {
    fetchSuspensionReasons(locale);
    return inPlayOverviewReset;
  }, []);

  if (!loaded) return <Skeleton skeletonType="in-play-overview in-play-minicomponent" />;

  const selectedSport = sports.find((s) => s.id === parseInt(selectedSportId, 10));
  const filteredSports = sports.filter((s) => !!s.comp.length);

  if (widget) {
    // widget mode and no sports, or widget mode with a group and group not found
    if (!sports || !sports.length || (isGroup
        && selectedSportId !== 'mini_inplay'
        && !slider.find((s) => s.id === parseInt(selectedSportId, 10))
    )) {
      return null;
    }
    return (
      <section className="in-play-overview in-play-minicomponent">
        <div className="bvs-header">
          <span className="inplay-list-header-title">
            <Icon id="inplay" big type="in-play-widget-icon" />
            { t('javascript.in_play.mini_component.header') }
          </span>
          <ButtonCard
            className="inplay-list-header-more"
            to="/live/overview"
          >
            { t('javascript.in_play.mini_component.more_events', { events: totalNumberOfEvents }) }
            <Icon id="arrow-right" />
          </ButtonCard>
        </div>
        <div className="bvs-card inplay">
          { sports.filter((s) => !!s.comp.length)
            .map((sport) => <Competitions sport={sport} showSportHeader key={sport.comp.id} />)}
          <GoToInPlay />
        </div>
      </section>
    );
  }

  const opts = { showMarketFilter: true };

  return (
    <section className={classnames('in-play-overview in-play-overview--full-component', treeType, { 'is-group': isGroup || selectedSportId === 'streamable' })} ref={rootNode}>
      <AppHeader selectedSport={selectedSport} treeType={treeType} isGroup={isGroup} />
      {fetching && <Spinner />}
      {sports.length > 0 && <SportSelector showSportHeader />}
      {selectedSport && <Competitions sport={selectedSport} {...opts} />}
      {selectedSportId === 'overview' && sports.length > 0 && <Competitions sport={sports[0]} {...opts} />}
      {(selectedSportId === 'streamable' || isGroup) && filteredSports.map((sport) => (
        <Competitions
          sport={sport}
          showMarketFilter
          showSportHeader
        />
      ))}
      {!selectedSportId && <NoEvents />}
    </section>
  );
};

App.propTypes = {
  sports: PropTypes.instanceOf(Array),
  slider: PropTypes.instanceOf(Array),
  treeType: PropTypes.string,
  fetchInPlayOverview: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  selectedSportId: PropTypes.string,
  widget: PropTypes.bool,
  totalNumberOfEvents: PropTypes.number,
  fetchSuspensionReasons: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  isGroup: PropTypes.bool.isRequired,
  inPlayOverviewReset: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
};

App.defaultProps = {
  sports: [],
  slider: [],
  treeType: 'in_play',
  selectedSportId: null,
  widget: false,
  totalNumberOfEvents: null,
  fetching: false,
};

const mapStateToProps = (state) => ({
  sports: state.inplay.sports,
  slider: state.inplay.slider,
  locale: state.inplay.locale,
  treeType: state.inplay.treeType,
  widget: state.inplay.widget,
  selectedSportId: state.inplay.selectedSportId,
  totalNumberOfEvents: state.inplay.totalNumberOfEvents,
  fetching: state.inplay.fetching,
  isGroup: state.inplay.isGroup,
  loaded: state.inplay.loaded,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInPlayOverview: (treeType) => {
    dispatch(inPlayOverviewSportsTreeFetch(treeType));
  },
  fetchSuspensionReasons: (locale) => {
    dispatch(SuspensionReasonsRedux.actionCreators.fetchSuspensionReasons(locale));
  },
  inPlayOverviewReset: () => {
    dispatch(inPlayOverviewResetAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
