import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { linkLocalizer } from 'bv-helpers/locale';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';

export const GoToInPlayComponent = ({ selectedSportId }) => {
  const url = linkLocalizer(
    `/live/${!Number.isNaN(Number(selectedSportId)) ? selectedSportId : 'overview'}`,
  );

  return (
    <div className="inplay-see-all more-button-wrapper">
      <Button href={url} primary>
        {t('javascript.in_play.mini_component.go_to_in_play')}
      </Button>
    </div>
  );
};

GoToInPlayComponent.propTypes = {
  selectedSportId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  selectedSportId: state.inplay.selectedSportId,
});

export default connect(mapStateToProps)(GoToInPlayComponent);
