import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'bv-components';

const MarketFilterItem = ({
  marketType,
  sportId,
  changeMarketType,
  active,
  activeRef,
}) => {
  const onClick = useCallback(() => {
    changeMarketType(
      sportId,
      marketType,
      marketType.periods[0],
    );
  }, [sportId, marketType, marketType.periods[0]]);

  return (
    <Button
      onClick={onClick}
      ref={active ? activeRef : null}
      noClass
      className={`bvs-button-chip ${active ? ' active' : ''}`}
    >
      {marketType.mtDesc}
    </Button>
  );
};

MarketFilterItem.propTypes = {
  sportId: PropTypes.number.isRequired,
  marketType: PropTypes.isRequired,
  changeMarketType: PropTypes.func.isRequired,
  activeRef: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
};

export default MarketFilterItem;
