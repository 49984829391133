import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeGetScoreboard } from 'InPlayOverview/selectors';
import { Redux as SuspensionReasonsRedux } from 'sportsbook-suspension-reasons';
import { t } from 'bv-i18n';

export const SuspendedMarketComponent = ({ reasons, scoreboard }) => {
  let reason = {};
  if (reasons && scoreboard.marketSuspensionReason) {
    reason = reasons
      .find((r) => r.type.toLowerCase() === scoreboard.marketSuspensionReason.toLowerCase()) || {};
  }

  return (
    <span className="bvs-button-multi-sport suspended">
      {t('javascript.suspended')}
      {reasons && reason.description && (
        <span className="inplay-coupon-suspension-reason">
          {reason.description}
        </span>
      )}
    </span>
  );
};

SuspendedMarketComponent.propTypes = {
  scoreboard: PropTypes.isRequired,
  reasons: PropTypes.isRequired,
};

const makeMapStateToProps = () => {
  const getScoreboard = makeGetScoreboard();
  const getSuspensionReasons = SuspensionReasonsRedux.selectors.makeGetSportSuspensionReasons();

  const mapStateToProps = (state, ownProps) => ({
    scoreboard: getScoreboard(state, ownProps),
    reasons: getSuspensionReasons(state, ownProps),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(SuspendedMarketComponent);
