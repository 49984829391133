import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import classnames from 'classnames';
import { Icon } from 'bv-components';
import TreeTypeSwitcher from './competitions/tree_type_switcher';

const AppHeader = ({ treeType, selectedSport, isGroup }) => (
  <div className={classnames('in-play-component-header', treeType)}>
    <div className="inplay-header-wrapper">
      {treeType !== 'upcoming' && <Icon id="inplay" big />}
      {`${treeType === 'upcoming' ? t('in_play.upcoming_events_header') : t('in_play.in_play_events_header')}`}
    </div>
    <TreeTypeSwitcher treeType={treeType} selectedSport={selectedSport} isGroup={isGroup} />
  </div>
);

AppHeader.propTypes = {
  selectedSport: PropTypes.isRequired,
  treeType: PropTypes.string,
  isGroup: PropTypes.isRequired,
};

AppHeader.defaultProps = {
  treeType: 'in_play',
};

export default AppHeader;
