import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { inPlayOverviewChangeSport } from 'InPlayOverview/duck';
import { Button } from 'bv-components';

const SportSelectorItemComponent = ({
  treeType,
  sport,
  locale,
  selectedSportId,
  changeSport,
  activeRef,
}) => {
  const isUpcoming = treeType === 'upcoming';
  const isGroup = sport.type === 'SportGroup';

  const url = `/${locale}/live/${isGroup ? 'group/' : ''}${sport.id}${isUpcoming ? '/upcoming' : ''}`;
  const active = selectedSportId === sport.id;

  return (
    <Button
      onClick={() => { changeSport(treeType, sport.id, isGroup); }}
      href={url}
      ref={active ? activeRef : null}
      className={active ? ' active bvs-button-tab' : 'bvs-button-tab'}
      noClass
    >
      <span className="inplay-sports-slider_name">{sport.desc}</span>
    </Button>
  );
};

SportSelectorItemComponent.propTypes = {
  locale: PropTypes.string.isRequired,
  sport: PropTypes.shape({
    desc: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  selectedSportId: PropTypes.number.isRequired,
  changeSport: PropTypes.func.isRequired,
  treeType: PropTypes.string.isRequired,
  activeRef: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.inplay.locale,
  selectedSportId: state.inplay.selectedSportId,
  treeType: state.inplay.treeType,
});

const mapDispatchToProps = (dispatch) => ({
  changeSport(treeType, sportId, isGroup) {
    dispatch(inPlayOverviewChangeSport(treeType, sportId, isGroup));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SportSelectorItemComponent);
