import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeGetOutcomes, getEvent } from 'InPlayOverview/selectors';
import { withCompetitionContext } from 'InPlayOverview/helpers/competition';
import orderedOutcomes from 'InPlayOverview/helpers/ordered_outcomes';
import shouldApplyAmericanFormat from 'InPlayOverview/helpers/should_apply_american_format';
import { isMarketSuspended } from 'sportsbook-helpers';
import SeeMoreMarkets from './see_more_markets';

export const OutcomesComponent = ({
  event, marketType, outcomes: outcomesProp, market,
}) => {
  const template = marketType.couponName;
  const showOutcomeDesc = ['template_a', 'template_b', 'template_b1', 'template_e'].includes(template);

  const outcomes = outcomesProp[market.id] || [];

  if (outcomes.length === 0) {
    return <SeeMoreMarkets />;
  }

  const outcomeKeys = marketType.outcome_keys || [];

  const useAmericanFormat = shouldApplyAmericanFormat(
    event.americanFormat,
    marketType.couponName,
    outcomeKeys,
  );

  return orderedOutcomes(
    outcomes,
    outcomeKeys,
    template,
    showOutcomeDesc,
    isMarketSuspended(market),
    useAmericanFormat,
  );
};

OutcomesComponent.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  marketType: PropTypes.instanceOf(Object).isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  outcomes: PropTypes.instanceOf(Object).isRequired,
};

const makeMapStateToProps = () => {
  const getOutcomes = makeGetOutcomes();

  const mapStateToProps = (state, ownProps) => ({
    outcomes: getOutcomes(state, { markets: [ownProps.market] }),
    event: getEvent(state, ownProps?.market?.eId),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(withCompetitionContext(OutcomesComponent));
