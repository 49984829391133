import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavigationContext from 'InPlayOverview/services/navigation_context';
import { getEvent, makeGetMarkets } from 'InPlayOverview/selectors';
import { withCompetitionContext } from 'InPlayOverview/helpers/competition';
import marketPicker from 'InPlayOverview/services/market_picker';
import { getEventHref } from 'InPlayOverview/helpers/navigation';
import { Overview } from 'sportsbook-common';

import SeeMoreMarkets from './see_more_markets';

export const OutrightEventComponent = ({
  markets,
  period,
  event,
  sport,
  competition,
  marketType,
  template,
  OutcomesComponent,
}) => {
  const selectedMarkets = marketType.marketTypeDimensions.map((mtdim) => (
    marketPicker(
      markets,
      { ...marketType, marketTypeDimensions: [mtdim] },
      period,
    )
  ));
  const hasAnyMarket = selectedMarkets.filter(Boolean).length !== 0;

  const eventHref = getEventHref({
    sport,
    competition,
    eventId: event.id,
  });

  const EventDetail = Overview.EventDetail.Factory(template, event.sport_id);

  return (
    <NavigationContext.Provider value={eventHref}>
      <div className="inplay-coupon-row">
        <EventDetail
          eventId={event.id}
          marketId={selectedMarkets[0]?.id}
          badgeFilter={(badge) => badge === 'STREAMING'}
        />
        <div className="inplay-coupon-odds-column">
          {
            hasAnyMarket ? (
              (marketType.headers || []).map(
                (h) => <span key={h} className="inplay-coupon-odds-label">{h}</span>,
              )
            ) : <SeeMoreMarkets />
          }
        </div>
      </div>
      {hasAnyMarket && <OutcomesComponent markets={selectedMarkets} />}
    </NavigationContext.Provider>
  );
};

OutrightEventComponent.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  markets: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  marketType: PropTypes.instanceOf(Object).isRequired,
  period: PropTypes.instanceOf(Object).isRequired,
  sport: PropTypes.instanceOf(Object).isRequired,
  competition: PropTypes.instanceOf(Object).isRequired,
  template: PropTypes.string.isRequired,
  OutcomesComponent: PropTypes.isRequired,
};

const makeMapStateToProps = () => {
  const getMarkets = makeGetMarkets();

  const mapStateToProps = (state, ownProps) => ({
    event: getEvent(state, ownProps.eventId),
    markets: getMarkets(state, ownProps.eventId),
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(withCompetitionContext(OutrightEventComponent));
