import PropTypes from 'prop-types';

import Event from 'InPlayOverview/components/coupons/event';
import MultiMarket from 'InPlayOverview/components/coupons/multi_market';

export const TemplateEComponent = (props) => props.events.map(
  (e) => <Event key={e} eventId={e} template="template_e" marketComponent={MultiMarket} marketClassName="inplay-coupon-odds-column-wrapper" />,
);

TemplateEComponent.propTypes = {
  events: PropTypes.instanceOf(Object).isRequired,
};

export default TemplateEComponent;
