import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import { createSelectorCreator, createSelector, defaultMemoize } from 'reselect';
import { isEqual } from 'underscore';

const createEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
);

export const getSports = (state) => state.inplay.sports;
export const getSelectedSportId = (state) => state.inplay.selectedSportId;
export const getEvents = (state) => state.inplay.events;
export const { getScoreboards } = EssentialScoresRedux.selectors;
export const getInPlayMarketIds = (state) => state.inplay.markets;
export const getInPlayOutcomeIds = (state) => state.inplay.outcomes;

export const getOwnCompetition = (state, props) => props.competition;
export const getOwnEventId = (state, props) => props.eventId;
export const getOwnMarkets = (state, props) => props.markets;

const identity = (state, props) => props;

export const makeGetSelectedSport = () => (
  createEqualSelector(
    [getSports, getSelectedSportId],
    (sports, sportId) => sports.find((s) => s.id === sportId) || sports[0],
  )
);

export const makeGetScoreboard = () => createEqualSelector(
  [getScoreboards, getOwnEventId],
  (scoreboards, eventId) => scoreboards[eventId] || {},
);

export const getScoreboard = createEqualSelector(
  [getScoreboards, identity],
  (scoreboards, eventId) => scoreboards[eventId] || {},
);

export const makeGetFirstScoreboard = () => createEqualSelector(
  [getScoreboards, getOwnCompetition, getEvents],
  (scoreboards, competition, events) => {
    const firstEvent = Object.values(events)
      .find((e) => competition.events.includes(e.id) && e.visible);
    if (!scoreboards || !firstEvent || !firstEvent.id) {
      return null;
    }
    return scoreboards[firstEvent.id];
  },
);

export const getEvent = createEqualSelector(
  [getEvents, identity],
  (events, id) => events[id] || {},
);

export const makeGetVisibleEvents = () => createSelector(
  [getOwnCompetition, getEvents],
  (
    competition, events,
  ) => Object.values(events).filter((e) => competition.events.includes(e.id) && e.visible),
);

export const getVisibleEvents = (state, competition) => {
  const eventIds = (competition && competition.events) || [];
  return Object.values(getEvents(state)).filter((e) => eventIds.includes(e.id) && e.visible);
};

const getAllOutcomes = (state) => state.outcomes;

export const makeGetOutcomes = () => createEqualSelector(
  [getAllOutcomes, getOwnMarkets],
  (outcomes, markets) => (
    markets.reduce((acc, m) => {
      acc[m.id] = (m.o || []).reduce((acc2, o) => {
        if (outcomes[o]) {
          acc2.push(outcomes[o]);
        }
        return acc2;
      }, []);
      return acc;
    }, {})
  ),
);

export const getOutcomes = (state, markets) => (
  markets.reduce((acc, m) => {
    acc[m.id] = (m.o || []).reduce((acc2, o) => {
      if (state.outcomes[o]) {
        acc2.push(state.outcomes[o]);
      }
      return acc2;
    }, []);
    return acc;
  }, {})
);

export const makeGetEvent = () => createEqualSelector(
  [getEvents, getOwnEventId],
  (events, eventId) => events[eventId] || {},
);

const getAllMarkets = (state) => state.markets;

export const makeGetMarkets = () => createEqualSelector(
  [getEvent, getAllMarkets],
  (event, markets) => event.markets?.reduce((acc, m) => {
    if (markets[m]) {
      acc.push(markets[m]);
    }
    return acc;
  }, []),
);

export const getMarkets = (
  state, eventId,
) => getEvent(state, eventId).markets.reduce((acc, m) => {
  if (state.markets[m]) {
    acc.push(state.markets[m]);
  }
  return acc;
}, []);

export const getSportForMarket = (state, market) => {
  const event = getEvents(state)[market.eId];
  if (event) {
    return getSports(state).find((s) => s.id === event.sport_id);
  }
  return null;
};
