import {
  pickOutcomesByKeys,
  orderOutcomesByPo,
} from 'InPlayOverview/helpers/outcomes';

const orderedOutcomes = (
  outcomes, keyOrder, template, showOutcomeDesc, suspended, useAmericanFormat = false,
) => {
  const keys = keyOrder.filter((key) => key !== '');
  const displayOutcomes = (outcomes.length <= keys.length)
    // if we have all keys assigned, or more keys than outcomes
    ? pickOutcomesByKeys(outcomes, keys, showOutcomeDesc, suspended)
    // fall back to sorting by po instead of picking elements
    : orderOutcomesByPo(outcomes, showOutcomeDesc, suspended);

  return useAmericanFormat ? displayOutcomes.toReversed() : displayOutcomes;
};

export default orderedOutcomes;
