import { registerHandler, unregisterHandler } from 'event-bus';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';

import {
  IN_PLAY_OVERVIEW_SPORTS_TREE_FETCH_SUCCESS,
  IN_PLAY_OVERVIEW_REMOVE,
  IN_PLAY_OVERVIEW_CHANGE_SPORT,
  IN_PLAY_OVERVIEW_CHANGE_SPORT_SUCCESS,
  IN_PLAY_OVERVIEW_CHANGE_FILTER,
  IN_PLAY_OVERVIEW_CHANGE_FILTER_SUCCESS,
  IN_PLAY_OVERVIEW_CHANGE_TREE_TYPE,
} from './duck';

import eventTransitionHandler from './services/event_transition_handler';
import marketUpdateHandler from './services/market_update_handler';

const registerEventTransition = (address) => {
  if (address) {
    registerHandler(address, eventTransitionHandler);
  }
};

const unregisterEventTransition = (address) => {
  if (address) {
    unregisterHandler(address, eventTransitionHandler);
  }
};

const extractEvents = (sport) => (
  (sport.comp || []).reduce((acc, c) => acc.concat(c.events), [])
);

const registerMarketUpdates = (sports) => {
  (sports || []).forEach((s) => {
    if (s.selectedMarketType) {
      extractEvents(s).forEach((e) => {
        s.selectedMarketType.marketTypeDimensions.forEach((mtDim) => {
          registerHandler(`/event/${e}/marketsummary-dim/${mtDim}`, marketUpdateHandler);
        });
      });
    }
  });
};

const unregisterMarketUpdates = (sports) => {
  (sports || []).forEach((s) => {
    if (s.selectedMarketType) {
      extractEvents(s).forEach((e) => {
        s.selectedMarketType.marketTypeDimensions.forEach((mtDim) => {
          unregisterHandler(`/event/${e}/marketsummary-dim/${mtDim}`, marketUpdateHandler);
        });
      });
    }
  });
};

export default (store) => (next) => (action) => {
  const state = store.getState();

  switch (action.type) {
    case IN_PLAY_OVERVIEW_SPORTS_TREE_FETCH_SUCCESS:
      if (!state.inplay.widget) {
        registerEventTransition(action.pgatePath);
      }
      registerMarketUpdates(action.sports);
      break;

    case IN_PLAY_OVERVIEW_CHANGE_SPORT:
      unregisterMarketUpdates(state.inplay.sports);
      break;

    case IN_PLAY_OVERVIEW_CHANGE_SPORT_SUCCESS:
      registerMarketUpdates(action.sports);
      break;

    case IN_PLAY_OVERVIEW_REMOVE:
      if (!state.inplay.widget) {
        unregisterEventTransition(state.inplay.pgatePath);
      }
      unregisterMarketUpdates(state.inplay.sports);
      break;

    case IN_PLAY_OVERVIEW_CHANGE_FILTER:
      unregisterMarketUpdates(state.inplay.sports);
      break;

    case IN_PLAY_OVERVIEW_CHANGE_FILTER_SUCCESS:
      registerMarketUpdates(state.inplay.sports);
      break;

    case IN_PLAY_OVERVIEW_CHANGE_TREE_TYPE:
      if (!state.inplay.widget) {
        unregisterEventTransition(state.inplay.pgatePath);
      }
      store.dispatch(
        EssentialScoresRedux.actionCreators.removeEssentialScores(Object.keys(state.inplay.events)),
      );
      unregisterMarketUpdates(state.inplay.sports);
      break;

    default:
      break;
  }
  next(action);
};
