import { v as bvVar } from 'bv';

const intersection = (a, b) => a.filter((x) => b.includes(x));
const homeAwayOutcomeKeys = ['HOME', 'AWAY'];
const couponTypesToUseAmericanFormat = [/template_a/, /template_b/, /template_d/, /template_e/];

export default (americanFormat, couponName, marketTypeOutcomeKeys) => (
  americanFormat
    && bvVar('useAmericanFormat')
    && couponTypesToUseAmericanFormat.some((pattern) => pattern.test(couponName))
    && intersection(marketTypeOutcomeKeys, homeAwayOutcomeKeys).length === 2
);
