import TemplateA from 'InPlayOverview/components/coupons/template_a';
import TemplateB from 'InPlayOverview/components/coupons/template_b';
import TemplateC from 'InPlayOverview/components/coupons/template_c';
import TemplateD from 'InPlayOverview/components/coupons/template_d';
import TemplateE from 'InPlayOverview/components/coupons/template_e';

export default (coupon) => {
  // Start out with 3 button coupon when nothing is configured
  let component = TemplateA;

  if (coupon === 'template_a') {
    component = TemplateA;
  }
  if (coupon === 'template_b') {
    component = TemplateA;
  }
  if (coupon === 'template_b1') {
    component = TemplateB;
  }
  if (coupon === 'template_c' || coupon === 'template_c3') {
    component = TemplateC;
  }
  if (coupon === 'template_d') {
    component = TemplateD;
  }
  if (coupon === 'template_e') {
    component = TemplateE;
  }

  return component;
};
