import PropTypes from 'prop-types';

import OutrightEvent from 'InPlayOverview/components/coupons/outright_event';
import OutrightOutcomes from './outright_outcomes';

export const TemplateCComponent = (props) => props.events.map(
  (e) => (
    <OutrightEvent
      key={e}
      eventId={e}
      template={props.coupon}
      OutcomesComponent={OutrightOutcomes}
    />
  ),
);

TemplateCComponent.propTypes = {
  events: PropTypes.isRequired,
};

export default TemplateCComponent;
