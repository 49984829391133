import PropTypes from 'prop-types';

const CouponHeader = ({
  couponName,
  headers,
  useAmericanFormat,
}) => {
  const isOutrightCoupon = /template_c/.test(couponName);
  const displayHeaders = useAmericanFormat ? (headers || []).toReversed() : (headers || []);

  if (isOutrightCoupon) return null;

  return (
    <div className="inplay-competition-header-row hidden-header">
      <div className="inplay-coupon-favcompetition-column" />
      <div className="inplay-coupon-odds-column">
        {displayHeaders.map((h) => (
          <span key={`${h}`} className="inplay-coupon-odds-label">{h}</span>
        ))}
      </div>
    </div>
  );
};

CouponHeader.propTypes = {
  headers: PropTypes.instanceOf(Array).isRequired,
  couponName: PropTypes.string.isRequired,
  useAmericanFormat: PropTypes.bool.isRequired,
};

export default CouponHeader;
