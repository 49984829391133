import PropTypes from 'prop-types';

import Event from 'InPlayOverview/components/coupons/event';
import Market from 'InPlayOverview/components/coupons/market';

export const TemplateAComponent = (props) => props.events.map(
  (e) => <Event key={e} eventId={e} template="template_a" marketComponent={Market} />,
);

TemplateAComponent.propTypes = {
  events: PropTypes.instanceOf(Object).isRequired,
};

export default TemplateAComponent;
