import { useCallback } from 'react';
import { transpose } from 'underscore';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMarketSuspended } from 'sportsbook-helpers';
import withOutcome from 'sportsbook-with-outcome';
import { ErrorBoundary } from 'bv-error-boundary';
import { t } from 'bv-i18n';
import { makeGetOutcomes } from 'InPlayOverview/selectors';
import { withCompetitionContext } from 'InPlayOverview/helpers/competition';
import { withNavigationContext } from 'InPlayOverview/helpers/navigation';
import { Link, Button } from 'bv-components';

const numberOfOutcomesToShow = 5;

const Outcome = withOutcome((props) => {
  if (!props.outcome || props.id === -1) {
    return (
      <span className="bvs-button-multi-sport disabled"> - </span>
    );
  }

  return (
    <span
      data-id={props.outcome.id}
      className={classnames('bvs-button-multi-sport', { active: props.selected, disabled: isMarketSuspended(props.market) })}
      onClick={props.toggleBetslip}
    >
      {props.outcome.formattedPrice}
    </span>
  );
});

export const OutrightOutcomesComponent = ({ outcomes, markets, eventHref }) => {
  const computedOutcomes = Object.values(outcomes)
    .flat()
    .filter((o) => !o?.h && !o?.hidden)
    .reduce((acc, el) => { // group by opId
      const { id: outcomeId, desc, opId } = el;
      const market = markets.find((m) => (m?.o || []).includes(outcomeId));
      if (!market) {
        return acc;
      }
      const key = `${desc} - ${opId}`;
      acc[key] = acc[key] || {};
      acc[key][market.id] = { ...el, elementKey: key, market };
      return acc;
    }, {});

  const sortedOutcomes = Object.values(computedOutcomes).reduce((acc, outcomeGroup) => {
    acc.push(markets.map((m) => (m && outcomeGroup[m.id]) || {
      id: -1,
      h: true,
      hidden: true,
      desc: (Object.values(outcomeGroup).at(0) || {})?.desc,
      market: { id: -1 },
    }));
    return acc;
  }, []);
  const sortColumnIndex = transpose(sortedOutcomes).findIndex(
    (col) => col.every((outcome) => outcome.id !== -1),
  ) || 0;
  sortedOutcomes.sort((a, b) => a[sortColumnIndex].prd - b[sortColumnIndex].prd);
  const limitedOutcomes = sortedOutcomes.slice(0, numberOfOutcomesToShow);

  const outcomeComponents = limitedOutcomes.map((outcomeGroup) => (
    <div className="inplay-coupon-row" key={outcomeGroup[0].elementKey}>
      <Link to={eventHref} className="inplay-coupon-competition-column">
        <div className="inplay-coupon-competition-team">
          <span className="inplay-coupon-team-name">{outcomeGroup[0].desc}</span>
        </div>
      </Link>
      <div className="inplay-coupon-odds-column">
        {outcomeGroup.map((o) => (
          <Outcome
            id={o.id}
            market={o.market}
            eventHref={eventHref}
            clickSource="in-play"
          />
        ))}
      </div>
    </div>
  ));

  const showAllMarkets = (
    <div className="inplay-see-all-link more-button-wrapper">
      <Button href={eventHref} primary>
        {t('javascript.in_play.show_all_markets')}
      </Button>
    </div>
  );

  if (numberOfOutcomesToShow < Object.keys(computedOutcomes).length) {
    outcomeComponents.push(showAllMarkets);
  }

  const FallbackComponent = useCallback(() => showAllMarkets, []);

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      {outcomeComponents}
    </ErrorBoundary>
  );
};

OutrightOutcomesComponent.propTypes = {
  markets: PropTypes.instanceOf(Object).isRequired,
  outcomes: PropTypes.instanceOf(Array).isRequired,
  eventHref: PropTypes.string.isRequired,
};

const makeMapStateToProps = () => {
  const getOutcomes = makeGetOutcomes();

  const mapStateToProps = (state, ownProps) => ({
    outcomes: getOutcomes(state, { markets: ownProps.markets.filter(Boolean) }),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(
  withNavigationContext(withCompetitionContext(OutrightOutcomesComponent)),
);
