import { getSportForMarket } from 'InPlayOverview/selectors';
import {
  inPlayAddMarket, inPlayRemoveMarket, inPlayAddOutcomes, inPlayRemoveOutcomes,
} from 'InPlayOverview/duck';
import { presentOutcome, presentMarket } from 'InPlayOverview/helpers/presenters';
import { isMarketClosed } from 'sportsbook-helpers';
import { addOutcomes, updateOutcome, removeOutcomes } from 'sportsbook-outcomes-duck';
import { addMarkets, updateMarket, removeMarkets } from 'sportsbook-markets-duck';

const isForTheCurrentPeriod = (state, market) => {
  const sport = getSportForMarket(state, market);
  const selectedPeriodDims = (
    sport && sport.selectedPeriod && sport.selectedPeriod.periodDimensions
  ) || [];
  return selectedPeriodDims.includes(market.pdim);
};

export default (err, { body: event }) => {
  const { dispatch } = window.reduxState.store;
  const state = window.reduxState.store.getState();
  const updatedMarkets = event.u || [];
  const newMarkets = event.n || [];

  updatedMarkets.forEach((m) => {
    const market = presentMarket(m);
    // if the market is not part of the periods we are currently watching for the sport
    // it belongs to, we don't care about it
    if (isForTheCurrentPeriod(state, market)) {
      // market is closing
      if (isMarketClosed(market)) {
        const oldOutcomes = (
          state.markets[market.id]
          && (state.markets[market.id].o
          || []).map((o) => state.outcomes[o])
        ) || [];
        const oldOutcomeIds = oldOutcomes.map((o) => o && o.id).filter((o) => o);
        dispatch(removeOutcomes(oldOutcomeIds));
        dispatch(inPlayRemoveOutcomes(oldOutcomeIds));
        dispatch(removeMarkets([market.id]));
        dispatch(inPlayRemoveMarket(market.id, market.eId));
      } else {
        if (market.o) {
          if (market.o.length > 0) {
            market.o.forEach((o) => {
              const outcome = presentOutcome(o);
              if (state.outcomes[outcome.id]) {
                dispatch(updateOutcome(outcome.id, outcome));
              } else {
                dispatch(addOutcomes([outcome]));
              }
            });
            market.o = market.o.map((o) => parseInt(o.oid, 10));
          }
        }
        dispatch(updateMarket(market.id, market));
      }
    }
  });

  newMarkets.forEach((m) => {
    const market = presentMarket(m);
    // if the market is not part of the periods we are currently watching for the sport
    // it belongs to, we don't care about it
    if (isForTheCurrentPeriod(state, market)) {
      if (market.o && market.o.length > 0) {
        const outcomes = market.o.map((o) => presentOutcome(o));
        const outcomeIds = outcomes.map((o) => o.oid || o.id).filter(Boolean);
        market.o = outcomeIds;
        dispatch(addOutcomes(outcomes));
        dispatch(inPlayAddOutcomes(outcomeIds));
      }
      dispatch(addMarkets([market]));
      dispatch(inPlayAddMarket(market.id, market.eId));
    }
  });
};
