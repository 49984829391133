export default (sports, events) => {
  sports.forEach((s) => {
    const eventsToShow = s.eventsToShow || s.ne || 10;
    s.eventsShown = 0;
    s.eventsToShow = eventsToShow;
    s.comp.forEach((c) => {
      c.events.forEach((e) => {
        if (eventsToShow > s.eventsShown) {
          events[e].visible = true;
          s.eventsShown += 1;
        } else {
          events[e].visible = false;
        }
      });
    });
  });
};

/* eslint no-param-reassign:"off" */
