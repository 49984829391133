import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { inPlayShowMore } from 'InPlayOverview/duck';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';

const PagerComponent = ({
  showMoreEvents, eventIncrement, numberOfEvents, eventsShown,
}) => (
  <div className="inplay-see-all more-button-wrapper">
    <Button onClick={showMoreEvents} primary>
      {t('javascript.more_elements_of_remaining', {
        elements: eventIncrement,
        remaining: numberOfEvents - eventsShown,
      })}
    </Button>
  </div>
);

PagerComponent.propTypes = {
  eventIncrement: PropTypes.number.isRequired,
  numberOfEvents: PropTypes.number.isRequired,
  eventsShown: PropTypes.number.isRequired,
  showMoreEvents: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showMoreEvents: () => {
    dispatch(inPlayShowMore(ownProps.sportId));
  },
});

export default connect(null, mapDispatchToProps)(PagerComponent);
