import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'bv-i18n';

export const TreeTypeSwitcherComponent = ({
  selectedSportId,
  selectedSport,
  treeType,
  isGroup,
}) => {
  const history = useHistory();
  const onToggle = useCallback((e) => {
    e.preventDefault();
    let newSportId = 'overview';
    if (isGroup || (selectedSport && selectedSport[`has${treeType === 'upcoming' ? 'Inplay' : 'Upcoming'}Events`])) {
      newSportId = selectedSportId;
    }
    history.replace(
      `/live/${isGroup ? 'group/' : ''}${newSportId}${treeType === 'in_play' ? '/upcoming' : ''}`,
    );
  }, [isGroup, selectedSport, selectedSportId, treeType]);

  return (
    <div className="inplay-toggler">
      <input type="checkbox" checked={treeType === 'upcoming'} id="inplay-toggle" className="inplay-toggle-checkbox" />
      <label htmlFor="inplay-toggle" className="inplay-toggler__container" onClick={onToggle}>
        <div>{t('javascript.in_play.in_play')}</div>
        <div>{t('javascript.in_play.upcoming')}</div>
      </label>
    </div>
  );
};

TreeTypeSwitcherComponent.propTypes = {
  selectedSportId: PropTypes.isRequired,
  selectedSport: PropTypes.bool.isRequired,
  treeType: PropTypes.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  selectedSportId: state.inplay.selectedSportId,
});

export default connect(mapStateToProps)(TreeTypeSwitcherComponent);
