import { omit } from 'underscore';
import { t } from 'bv-i18n';

const parseSliderElements = (response) => [
  ...(response.total_number_of_streamable_events
    ? [{
      id: 'streamable',
      desc: t('javascript.in_play.streams'),
      type: 'Sport',
    }]
    : []
  ),
  ...response.slider_elements,
];

export default (response) => ({
  ...omit(response, 'slider_elements'),
  sliderElements: parseSliderElements(response),
});
