import withOutcome from 'sportsbook-with-outcome';
import { formatPrice } from 'sportsbook-helpers';
import { usePriceFormatId } from 'bv-hooks';

const Outcome = withOutcome((props) => {
  const priceFormatId = usePriceFormatId();
  if (!props.outcome || !props.outcome.id) {
    return null;
  }

  const shortDescription = !props.outcome.h && props.showOutcomeDesc && props.outcome.sDesc;

  return (
    <span
      data-id={props.outcome.id}
      className={`bvs-button-multi-sport ${props.selected ? 'active' : ''} ${props.suspended ? 'disabled' : ''}`}
      onClick={props.outcome.h || props.suspended ? () => {} : props.toggleBetslip}
    >
      {shortDescription && <span className="short-description">{shortDescription}</span>}
      {!props.outcome.h ? formatPrice(props.outcome.pr, priceFormatId) : '-'}
    </span>
  );
});

export const makeOutcome = (outcome, showOutcomeDesc, suspended) => (
  outcome
    ? (
      <Outcome
        key={outcome.id}
        id={outcome.id}
        showOutcomeDesc={showOutcomeDesc}
        suspended={suspended}
        clickSource="in-play"
      />
    )
    : <span className="bvs-button-multi-sport"><span className="price">-</span></span>
);

export const pickOutcomesByKeys = (outcomes, keyOrder, showOutcomeDesc, suspended) => (
  keyOrder.map(
    (key) => makeOutcome(outcomes.find((o) => o.keyDimension === key), showOutcomeDesc, suspended),
  )
);

export const orderOutcomesByPo = (outcomes, showOutcomeDesc, suspended) => (
  outcomes.sort((o1, o2) => o1.po - o2.po).map((o) => makeOutcome(o, showOutcomeDesc, suspended))
);
