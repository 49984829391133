import {
  values, keys, uniq, clone, mapObject, property,
} from 'underscore';
import { fetchSports, fetchMarkets } from 'InPlayOverview/services/api';
import { Api as SuspensionReasonsApi } from 'sportsbook-suspension-reasons';
import applyPagination from 'InPlayOverview/services/pagination';
import { addMarkets, upsertMarkets, removeMarkets } from 'sportsbook-markets-duck';
import { addOutcomes, upsertOutcomes, removeOutcomes } from 'sportsbook-outcomes-duck';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import { upsertEvents, removeEvents } from 'sportsbook-events-duck';

const groupBy = (arr, key) => (
  arr.reduce((acc, item) => {
    (acc[item[key]] = acc[item[key]] || []).push(item);
    return acc;
  }, {})
);

export const IN_PLAY_OVERVIEW_INIT = 'inPlayOverview/INIT';
export const IN_PLAY_OVERVIEW_REMOVE = 'inPlayOverview/REMOVE';
export const IN_PLAY_OVERVIEW_SPORTS_TREE_FETCH = 'inPlayOverview/SPORTS_TREE_FETCH';
export const IN_PLAY_OVERVIEW_SPORTS_TREE_FETCH_SUCCESS = 'inPlayOverview/SPORTS_TREE_FETCH_SUCCESS';
export const IN_PLAY_OVERVIEW_CHANGE_SPORT = 'inPlayOverview/CHANGE_SPORT';
export const IN_PLAY_OVERVIEW_CHANGE_SPORT_SUCCESS = 'inPlayOverview/CHANGE_SPORT_SUCCESS';
export const IN_PLAY_OVERVIEW_CHANGE_FILTER = 'inPlayOverview/CHANGE_FILTER';
export const IN_PLAY_OVERVIEW_CHANGE_FILTER_SUCCESS = 'inPlayOverview/CHANGE_FILTER_SUCCESS';
export const IN_PLAY_OVERVIEW_ADD_MARKET = 'inPlayOverview/ADD_MARKET';
export const IN_PLAY_OVERVIEW_REMOVE_MARKET = 'inPlayOverview/REMOVE_MARKET';
export const IN_PLAY_OVERVIEW_ADD_OUTCOMES = 'inPlayOverview/ADD_OUTCOMES';
export const IN_PLAY_OVERVIEW_REMOVE_OUTCOMES = 'inPlayOverview/REMOVE_OUTCOMES';
export const IN_PLAY_OVERVIEW_SHOW_MORE = 'inPlayOverview/SHOW_MORE';
export const IN_PLAY_OVERVIEW_CHANGE_TREE_TYPE = 'inPlayOverview/CHANGE_TREE_TYPE';
export const IN_PLAY_OVERVIEW_SUSPENSION_REASONS_FETCH = 'inPlayOverview/SUSPENSION_REASONS_FETCH';
export const IN_PLAY_OVERVIEW_SUSPENSION_REASONS_FETCH_SUCCESS = 'inPlayOverview/SUSPENSION_REASONS_FETCH_SUCCESS';

export const inPlayOverviewInit = (opts) => ({
  type: IN_PLAY_OVERVIEW_INIT,
  ...opts,
});

const inPlayOverviewRemove = () => ({
  type: IN_PLAY_OVERVIEW_REMOVE,
});

export const inPlayOverviewReset = () => (dispatch, getState) => {
  const state = getState();

  dispatch(removeOutcomes(state.inplay.outcomes || []));
  dispatch(removeMarkets(state.inplay.markets || []));
  dispatch(EssentialScoresRedux.actionCreators.removeEssentialScores(
    Object.keys(state.inplay.events),
  ));
  dispatch(removeEvents(Object.values(state.inplay.events).map(property('id'))));
  dispatch(inPlayOverviewRemove());
};

export const inPlayOverviewChangeSport = (
  treeType,
  sportId,
  isGroup,
  clearMarketsAndOutcomes = true,
  version = null,
) => (dispatch, getState) => {
  let state = getState();

  if (!state.inplay.locale || !state.inplay.brandId) {
    return;
  }

  fetchSports({
    treeType,
    sportId,
    isGroup,
    widget: state.inplay.widget,
    oldSports: state.inplay.sports,
    locale: state.inplay.locale,
    brandId: state.inplay.brandId,
    version,
  }).then((response) => {
    state = getState();
    const {
      sports, events, markets, outcomes, scoreboards,
    } = response;
    const slider = response.sliderElements;

    state.inplay.sports.forEach((oldSport) => {
      const newSport = sports.find((s) => oldSport.id === s.id);
      if (!newSport) {
        return;
      }

      // copy over old pagination values
      newSport.eventsToShow = oldSport.eventsToShow;

      // "changing" to the same sport, keep the selection if it was previously loaded
      if (oldSport.selectedMarketType.id) {
        newSport.selectedMarketType = oldSport.selectedMarketType;
      }
      if (oldSport.selectedPeriod.id) {
        newSport.selectedPeriod = oldSport.selectedPeriod;
      }
    });

    if (clearMarketsAndOutcomes) {
      // release old markets and outcomes
      dispatch(removeOutcomes(state.inplay.outcomes || []));
      dispatch(removeMarkets(state.inplay.markets || []));

      // add in new markets and outcomes if it was cleared out before
      dispatch(addOutcomes(values(outcomes)));
      dispatch(addMarkets(values(markets)));
    } else {
      // add in new markets and outcomes if it was cleared out before
      dispatch(upsertOutcomes(values(outcomes)));
      dispatch(upsertMarkets(values(markets)));
    }

    applyPagination(sports, events);

    let selectedSportId = sportId;
    // if the sportId on the state is overview pick the first sport
    if (sportId === 'overview') {
      selectedSportId = (sports[0] && sports[0].id) || undefined;
    }
    // if the selected sport we are changing to is a number check if that sport is
    // available and if not pick the first sport
    if (!Number.isNaN(Number(sportId))) {
      selectedSportId = (sports.find((s) => s.id === sportId) || sports[0] || {}).id;
    }
    // if selected sports is the 'streamable' tab go just use that
    if (sportId === 'streamable' || sportId === 'mini_inplay' || isGroup) {
      selectedSportId = sportId;
    }

    // Update scoreboards
    dispatch(EssentialScoresRedux.actionCreators.removeEssentialScores(
      Object.keys(state.inplay.events),
    ));
    dispatch(EssentialScoresRedux.actionCreators.upsertEssentialScores(
      Object.values(scoreboards),
    ));

    // Update events -------------------------------------------------------------------------------
    dispatch(removeEvents(Object.values(state.inplay.events).map(property('id'))));
    dispatch(upsertEvents(Object.values(events)));

    dispatch({
      type: IN_PLAY_OVERVIEW_CHANGE_SPORT_SUCCESS,
      sports,
      slider,
      events,
      markets: Object.keys(markets),
      outcomes: Object.keys(outcomes),
      sportId: selectedSportId,
      totalNumberOfStreams: response.total_number_of_streamable_events,
      totalNumberOfEvents: response.total_number_of_events,
      isGroup,
    });
  });

  dispatch({
    type: IN_PLAY_OVERVIEW_CHANGE_SPORT,
    clearMarketsAndOutcomes,
  });
};

export const inPlayOverviewSportsTreeFetchSuccess = (data) => ({
  type: IN_PLAY_OVERVIEW_SPORTS_TREE_FETCH_SUCCESS,
  sports: data.sports,
  slider: data.sliderElements,
  events: data.events,
  markets: data.markets,
  outcomes: data.outcomes,
  pgatePath: data.pgatePath,
  totalNumberOfStreams: data.total_number_of_streamable_events,
  totalNumberOfEvents: data.total_number_of_events,
  selectedSportId: data.selectedSportId,
  isGroup: data.isGroup,
});

export const inPlayOverviewSportsTreeFetch = (treeType = 'in_play') => (dispatch, getState) => {
  const state = getState();
  const {
    inplay: {
      selectedSportId, isGroup, widget, sports, locale, brandId,
    },
  } = state;

  if (!locale || !brandId) {
    return;
  }

  fetchSports({
    treeType,
    sportId: selectedSportId,
    isGroup,
    widget,
    oldSports: sports,
    locale,
    brandId,
  }).then((response) => {
    response.selectedSportId = selectedSportId;

    let selectedSport = null;
    if (selectedSportId) {
      selectedSport = (response.sports || [])
        .find((s) => s.id === parseInt(selectedSportId, 10)) || false;
    }
    if (!selectedSport) {
      const filteredSliderItems = response.sliderElements.filter((el) => el.id !== 'streamable');
      // Try finding the selectedSportId if it's a group in the list of slider items
      if (isGroup) {
        selectedSport = filteredSliderItems.find((el) => el.id === selectedSportId);
      }
      // fall back to the first sport if all else fails
      if (!selectedSport) {
        selectedSport = filteredSliderItems[0] || {};
      }
      // selected sport is a single sport, but it's not available,
      // switch to the first sport as selected
      if (!widget
          && !Number.isNaN(Number(selectedSportId))
          && selectedSportId !== selectedSport.id
      ) {
        dispatch(inPlayOverviewChangeSport(treeType, selectedSport.id, selectedSport.type === 'SportGroup'));
        return;
      }
    }

    if (selectedSportId === 'overview') {
      const chosenSport = response.sports.length
        && response.sports.find((s) => s.id === selectedSport.id);

      if (chosenSport && chosenSport.comp && !chosenSport.comp.length) {
        dispatch(inPlayOverviewChangeSport(treeType, selectedSport.id, selectedSport.type === 'SportGroup'));
        return;
      }

      response.selectedSportId = selectedSport.id;
    }

    // When are going to streamable from a different tree-type
    // (from upcoming to inplay or vice versa)
    // but in the new playload there is no streamable event returned,
    // we need to do a new fetch with a
    // different sport and switch to that sport.
    if (selectedSportId === 'streamable' && response.total_number_of_streamable_events === 0) {
      dispatch(inPlayOverviewSportsTreeFetchSuccess({
        selectedSportId: response.sports[0].id,
        markets: [],
        outcomes: [],
      }));
      inPlayOverviewSportsTreeFetch(treeType)(dispatch, getState);
      return;
    }

    dispatch(addOutcomes(values(response.outcomes)));
    dispatch(addMarkets(values(response.markets)));

    applyPagination(response.sports, response.events);

    dispatch(EssentialScoresRedux.actionCreators.upsertEssentialScores(
      Object.values(response.scoreboards),
    ));
    dispatch(upsertEvents(Object.values(response.events)));

    dispatch(inPlayOverviewSportsTreeFetchSuccess({
      ...response,
      isGroup: selectedSport.type === 'SportGroup',
      markets: Object.keys(response.markets),
      outcomes: Object.keys(response.outcomes),
    }));
  });

  dispatch({ type: IN_PLAY_OVERVIEW_SPORTS_TREE_FETCH });
};

export const inPlayOverviewChangeFilter = (
  sportId, selectedMarketType, selectedPeriod,
) => (dispatch) => {
  let state = window.reduxState.store.getState();

  fetchMarkets({
    sportId,
    eventIds: Object.values(state.inplay.events).filter((e) => e.sport_id === sportId)
      .map((e) => e.id),
    marketType: selectedMarketType.id,
    period: selectedPeriod.id,
    locale: state.inplay.locale,
    brandId: state.inplay.brandId,
  }).then((response) => {
    state = window.reduxState.store.getState();
    const marketsByEventId = groupBy(values(response.markets), 'eId');
    const events = values(state.inplay.events || {}).reduce((acc, e) => {
      if (e.sport_id === sportId) {
        acc[e.id] = { ...e, markets: (marketsByEventId[e.id] || []).map((m) => m.id) || [] };
      } else {
        acc[e.id] = e;
      }
      return acc;
    }, {});

    dispatch(addOutcomes(values(response.outcomes)));
    dispatch(addMarkets(values(response.markets)));

    dispatch({
      type: IN_PLAY_OVERVIEW_CHANGE_FILTER_SUCCESS,
      sportId,
      events,
      markets: uniq(state.inplay.markets.concat(keys(response.markets))) || [],
      outcomes: uniq(state.inplay.outcomes.concat(keys(response.outcomes))) || [],
    });
  });

  const marketsToRemove = [];
  const outcomesToRemove = [];
  Object.values(state.inplay.events).filter((e) => e.sport_id === sportId).forEach((event) => {
    event.markets.forEach((marketId) => {
      marketsToRemove.push(marketId);
      if (state.markets[marketId]) {
        outcomesToRemove.push(...(state.markets[marketId].o || []));
      }
    });
  });

  dispatch(removeOutcomes(outcomesToRemove));
  dispatch(removeMarkets(marketsToRemove));

  dispatch({
    type: IN_PLAY_OVERVIEW_CHANGE_FILTER,
    sportId,
    marketType: selectedMarketType,
    period: selectedPeriod,
    markets: uniq(state.inplay.markets.filter((m) => !marketsToRemove.includes(m))) || [],
    outcomes: uniq(state.inplay.outcomes.filter((o) => !outcomesToRemove.includes(o))) || [],
  });
};

export const inPlayAddMarket = (marketId, eventId) => ({
  type: IN_PLAY_OVERVIEW_ADD_MARKET,
  marketId,
  eventId,
});

export const inPlayRemoveMarket = (marketId, eventId) => ({
  type: IN_PLAY_OVERVIEW_REMOVE_MARKET,
  marketId,
  eventId,
});

export const inPlayRemoveOutcomes = (outcomeIds) => ({
  type: IN_PLAY_OVERVIEW_REMOVE_OUTCOMES,
  outcomeIds,
});

export const inPlayAddOutcomes = (outcomeIds) => ({
  type: IN_PLAY_OVERVIEW_ADD_OUTCOMES,
  outcomeIds,
});

export const inPlayShowMore = (sportId) => {
  const state = window.reduxState.store.getState();
  const events = clone(state.inplay.events);
  const sports = state.inplay.sports.map((sport) => (
    sport.id === sportId
      ? {
        ...sport,
        eventsToShow: sport.eventsToShow + sport.eic,
      }
      : sport
  ));

  applyPagination(sports, events);

  return {
    type: IN_PLAY_OVERVIEW_SHOW_MORE,
    sports,
    events,
  };
};

export const inPlayChangeTreeType = (treeType, sportId) => ({
  type: IN_PLAY_OVERVIEW_CHANGE_TREE_TYPE,
  treeType,
  sportId,
});

export const inPlaySuspensionReasonsFetch = (locale) => (dispatch) => {
  SuspensionReasonsApi.fetchSuspensionReasons(locale).then((response) => {
    dispatch({
      type: IN_PLAY_OVERVIEW_SUSPENSION_REASONS_FETCH_SUCCESS,
      suspensionReasons: response,
    });
  });
};

const initialState = {
  locale: null,
  brandId: null,
  selectedSportId: null,
  isGroup: false,
  sports: [],
  slider: [],
  events: {},
  markets: [],
  outcomes: [],
  suspensionReasons: {},
  pgatePath: '',
  treeType: 'in_play',
  widget: false,
  fetching: false,
  fetchingSport: false,
  fetchingMarkets: false,
  totalNumberOfStreams: 0,
  totalNumberOfEvents: 0,
  loaded: false,
};

export default (state = initialState, action = {}) => {
  let sports;

  switch (action.type) {
    case IN_PLAY_OVERVIEW_INIT:
      return {
        ...state,
        locale: action.locale,
        treeType: action.treeType || 'in_play',
        widget: action.widget || false,
        brandId: parseInt(action.brandId, 10) || 1,
        selectedSportId: action.sportId,
        isGroup: action.isGroup,
        loaded: true,
      };

    case IN_PLAY_OVERVIEW_REMOVE:
      return {
        ...state,
        ...initialState,
      };

    case IN_PLAY_OVERVIEW_SPORTS_TREE_FETCH:
      return {
        ...state,
        fetching: true,
      };

    case IN_PLAY_OVERVIEW_SPORTS_TREE_FETCH_SUCCESS:
      return {
        ...state,
        sports: action.sports,
        slider: action.slider,
        events: action.events,
        markets: action.markets.map((m) => parseInt(m, 10)),
        outcomes: action.outcomes.map((o) => parseInt(o, 10)),
        pgatePath: action.pgatePath,
        selectedSportId: action.selectedSportId,
        totalNumberOfStreams: action.totalNumberOfStreams,
        totalNumberOfEvents: action.totalNumberOfEvents,
        isGroup: action.isGroup,
        fetching: false,
      };

    case IN_PLAY_OVERVIEW_CHANGE_SPORT:
      return {
        ...state,
        fetchingSport: true,
      };

    case IN_PLAY_OVERVIEW_CHANGE_SPORT_SUCCESS:
      return {
        ...state,
        sports: action.sports,
        slider: action.slider,
        events: action.events,
        markets: action.markets.map((m) => parseInt(m, 10)),
        outcomes: action.outcomes.map((o) => parseInt(o, 10)),
        selectedSportId: action.sportId,
        totalNumberOfStreams: action.totalNumberOfStreams,
        totalNumberOfEvents: action.totalNumberOfEvents,
        fetching: false,
        fetchingSport: false,
        isGroup: action.isGroup,
      };

    case IN_PLAY_OVERVIEW_CHANGE_FILTER:
      sports = state.sports.map((sport) => (
        sport.id === action.sportId
          ? {
            ...sport,
            selectedMarketType: action.marketType,
            selectedPeriod: action.period,
          }
          : sport
      ));

      return {
        ...state,
        sports,
        fetchingMarkets: true,
        markets: action.markets.map((m) => parseInt(m, 10)),
        outcomes: action.outcomes.map((o) => parseInt(o, 10)),
      };

    case IN_PLAY_OVERVIEW_CHANGE_FILTER_SUCCESS:
      return {
        ...state,
        events: action.events,
        markets: action.markets.map((m) => parseInt(m, 10)),
        outcomes: action.outcomes.map((o) => parseInt(o, 10)),
        fetchingMarkets: false,
      };

    case IN_PLAY_OVERVIEW_ADD_MARKET:
      return {
        ...state,
        markets: [...state.markets, action.marketId].map((id) => parseInt(id, 10)),
        events: mapObject(state.events, (event) => {
          if (action.eventId === event.id) {
            return {
              ...event,
              markets: [...event.markets, action.marketId].map((id) => parseInt(id, 10)),
            };
          }
          return event;
        }),
      };

    case IN_PLAY_OVERVIEW_REMOVE_MARKET:
      return {
        ...state,
        markets: state.markets.filter((marketId) => marketId !== action.marketId),
        events: mapObject(state.events, (event) => {
          if (action.eventId === event.id) {
            return {
              ...event,
              markets: event.markets.filter((marketId) => marketId !== action.marketId),
            };
          }
          return event;
        }),
      };

    case IN_PLAY_OVERVIEW_ADD_OUTCOMES:
      return {
        ...state,
        outcomes: [...state.outcomes, ...action.outcomeIds].map((id) => parseInt(id, 10)),
      };

    case IN_PLAY_OVERVIEW_REMOVE_OUTCOMES:
      return {
        ...state,
        outcomes: state.outcomes.filter((o) => !action.outcomeIds.includes(o)),
      };

    case IN_PLAY_OVERVIEW_SHOW_MORE:
      return {
        ...state,
        sports: action.sports,
        events: action.events,
      };

    case IN_PLAY_OVERVIEW_CHANGE_TREE_TYPE:
      return {
        ...state,
        selectedSportId: action.sportId,
        treeType: action.treeType,
        sports: [],
        slider: [],
        events: {},
        markets: [],
        outcomes: [],
      };

    case IN_PLAY_OVERVIEW_SUSPENSION_REASONS_FETCH_SUCCESS:
      return {
        ...state,
        suspensionReasons: action.suspensionReasons,
      };

    default:
      return state;
  }
};
