import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { makeGetEvent, makeGetMarkets } from 'InPlayOverview/selectors';
import NavigationContext from 'InPlayOverview/services/navigation_context';
import { withCompetitionContext } from 'InPlayOverview/helpers/competition';
import { getEventHref } from 'InPlayOverview/helpers/navigation';
import { Overview } from 'sportsbook-common';

export const EventComponent = ({
  event,
  eventId,
  sport,
  competition,
  treeType,
  markets,
  template,
  marketComponent: Market,
  marketClassName,
  marketType,
}) => {
  const eventHref = getEventHref({
    sport,
    competition,
    eventId: event.id,
  });

  if (!event.visible) {
    return null;
  }

  const EventDetail = Overview.EventDetail.Factory(template, event.sport_id);
  const defaultInPlay = treeType !== 'upcoming';

  return (
    <NavigationContext.Provider value={eventHref}>
      <div className="inplay-coupon-row">
        <Overview.EventComment
          eventId={event.id}
        />
        <EventDetail
          eventId={event.id}
          eventHref={eventHref}
          defaultInPlay={defaultInPlay}
          showClock
          showScoreboardHeaders={treeType !== 'upcoming'}
          badgeFilter={(badge) => badge === 'STREAMING'}
        />
        <div className={classnames(marketClassName, `inplay-headers-count-${marketType?.headers.length}`)}>
          <Market markets={markets} eventId={eventId} />
        </div>
      </div>
    </NavigationContext.Provider>
  );
};

EventComponent.propTypes = {
  eventId: PropTypes.number.isRequired,
  event: PropTypes.instanceOf(Object),
  markets: PropTypes.instanceOf(Object).isRequired,
  competition: PropTypes.instanceOf(Object).isRequired,
  sport: PropTypes.instanceOf(Object).isRequired,
  treeType: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  marketComponent: PropTypes.instanceOf(Object).isRequired,
  marketClassName: PropTypes.string,
  marketType: PropTypes.instanceOf(Object).isRequired,
};

EventComponent.defaultProps = {
  event: null,
  marketClassName: 'inplay-coupon-odds-column',
};

const makeMapStateToProps = () => {
  const getEvent = makeGetEvent();
  const getMarkets = makeGetMarkets();

  return (state, ownProps) => ({
    event: getEvent(state, ownProps),
    markets: getMarkets(state, ownProps.eventId),
    treeType: state.inplay.treeType,
  });
};

export default connect(makeMapStateToProps, null)(withCompetitionContext(EventComponent));
