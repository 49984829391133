import NavigationContext from 'InPlayOverview/services/navigation_context';

import { preEventPath } from 'bv-helpers/routes';

export const getEventHref = (opts) => preEventPath({
  sportEventPathId: opts.sport.epId,
  meetingId: opts.competition.id,
  eventId: opts.eventId,
});

export const withNavigationContext = (Component) => (props) => (
  <NavigationContext.Consumer>
    {(eventHref) => (
      <Component
        {...props}
        eventHref={eventHref}
      />
    )}
  </NavigationContext.Consumer>
);
