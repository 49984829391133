import {
  useRef, useEffect, useContext, useState, useCallback,
} from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { isDesktop } from 'bv';
import { compose, throttle, noop } from 'underscore';
import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import MarketfilterContext from 'InPlayOverview/services/marketfilter_context';
import { inPlayOverviewChangeFilter } from 'InPlayOverview/duck';

import MarketFilterItem from './market_filter_item';

export const MarketFilterSlider = ({ marketTypes }) => {
  const activeRef = useRef();
  const dispatch = useDispatch();
  const { marketType, sport } = useContext(MarketfilterContext);
  const changeMarketType = useCallback(compose(dispatch, inPlayOverviewChangeFilter));
  const [fixedNav, setFixedNav] = useState(false);

  // Listen for scroll and acts accordingly
  useEffect(() => {
    if (isDesktop()) return noop;

    const scrollAmount = 96;

    const calculateFixedNav = throttle(() => {
      setFixedNav(window.scrollY >= scrollAmount);
    }, 150);

    window.addEventListener('scroll', calculateFixedNav);

    return () => { window.removeEventListener('scroll', calculateFixedNav); };
  }, []);

  return (
    <Carousel scrollStep={75} activeRef={activeRef} className={classnames('inplay-market-type-slider-wrapper', { 'is-scrolling': fixedNav })} embedded>
      {marketTypes.map((mt) => (
        <MarketFilterItem
          key={mt.id}
          marketType={mt}
          sportId={sport.id}
          activeRef={activeRef}
          active={mt.id === marketType.id}
          changeMarketType={changeMarketType}
        />
      ))}
    </Carousel>
  );
};

MarketFilterSlider.propTypes = {
  marketTypes: PropTypes.instanceOf(Array).isRequired,
};

export default MarketFilterSlider;
