import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import marketPicker from 'InPlayOverview/services/market_picker';
import { withCompetitionContext } from 'InPlayOverview/helpers/competition';

import Outcomes from './outcomes';
import SeeMoreMarkets from './see_more_markets';

export const MultiMarketComponent = ({
  markets, marketType, eventId,
}) => {
  if (!markets.length) {
    return <SeeMoreMarkets />;
  }

  return marketType.marketTypeDimensions.map((mtdim, i) => (
    <div className="inplay-coupon-odds-column">
      {
        (() => {
          const market = marketPicker(
            markets,
            { ...marketType, marketTypeDimensions: [mtdim] },
            marketType.periods[i],
          );
          if (market) {
            return (
              <ErrorBoundary FallbackComponent={SeeMoreMarkets} key={`${eventId}-${mtdim}`}>
                <Outcomes
                  market={market}
                  marketType={{
                    ...marketType,
                    // always have 3 keys with empty strings padded
                    outcome_keys: marketType.outcome_keys.slice(i * 3, i * 3 + 3),
                  }}
                />
              </ErrorBoundary>
            );
          }
          return (
            <SeeMoreMarkets key={`${eventId}-${mtdim}`} />
          );
        })()
      }
    </div>
  ));
};

MultiMarketComponent.propTypes = {
  markets: PropTypes.instanceOf(Object).isRequired,
  eventId: PropTypes.number.isRequired,
  marketType: PropTypes.instanceOf(Object).isRequired,
};

export default withCompetitionContext(MultiMarketComponent);
