import { random } from 'underscore';
import { inPlayOverviewChangeSport } from 'InPlayOverview/duck';

export default (err, { body: event }) => {
  const { store } = window.reduxState;
  const { inplay: { selectedSportId: oldSelectedSportId } } = store.getState();

  // Add random timeout between 5..7 seconds so cache backend
  // cache expired by the time we fetch the tree and not everyone
  // will fetch at the same moment to avoid cache stampede
  window.setTimeout(() => {
    const { inplay: { treeType, selectedSportId, isGroup } } = store.getState();
    const transitions = (event.eventsAdded || []).concat(event.eventsRemoved || []);

    // if we didn't change selected sport since the timer started
    if (oldSelectedSportId !== selectedSportId) return;

    // figure out if the current sport is affected by the trasitions
    // if it isn't we can skip the unsub/sub markets and outcomes and scoreboards on display
    const clearMarketsAndOutcomes = !Number.isNaN(Number(selectedSportId))
      && transitions.some((e) => e.sId === selectedSportId);

    store.dispatch(inPlayOverviewChangeSport(
      treeType,
      selectedSportId,
      isGroup,
      clearMarketsAndOutcomes,
      event.version,
    ));
  }, random(5000, 7000));
};
