import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Carousel } from 'bv-components';

import SportSelectorItem from './sport_selector_item';

export class SportSelectorComponent extends Component {
  constructor(props) {
    super(props);
    this.activeRef = createRef();
  }

  render() {
    const { slider } = this.props;
    return (
      <Carousel scrollStep={75} activeRef={this.activeRef} className="inplay-sports-slider-wrapper" embedded>
        {slider.map((sport) => (
          <SportSelectorItem key={sport.id} sport={sport} activeRef={this.activeRef} />
        ))}
      </Carousel>
    );
  }
}

SportSelectorComponent.defaultProps = {
  slider: [],
};

SportSelectorComponent.propTypes = {
  slider: PropTypes.arrayOf(PropTypes.shape),
};

const mapStateToProps = (state) => ({
  slider: state.inplay.slider,
});

export default connect(mapStateToProps, null)(SportSelectorComponent);
