import PropTypes from 'prop-types';

import Event from 'InPlayOverview/components/coupons/event';
import Market from 'InPlayOverview/components/coupons/market';

export const TemplateBComponent = (props) => props.events.map(
  (e) => <Event key={e} eventId={e} template="template_b" marketComponent={Market} />,
);

TemplateBComponent.propTypes = {
  events: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default TemplateBComponent;
