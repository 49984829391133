export const presentMarket = (market) => {
  const m = {
    id: parseInt(market.id, 10),
    desc: market.desc,
    eId: parseInt(market.eid, 10),
    pltD: market.ptd,
    pltDes: market.ptdesc,
    st: market.st,
    mbl: market.mblindex,
    current: market.cur,
    next: market.next,
    pid: market.pid,
    mtdim: market.mtdim,
    pdim: market.pdim,
    pinputs: market.pinputs,
  };
  if (market.o) {
    m.o = market.o;
  }
  return m;
};

export const presentOutcome = (outcome) => {
  const o = {
    id: parseInt(outcome.oid, 10),
    desc: outcome.d,
    prid: outcome.cpid,
    pr: outcome.pr,
    prd: outcome.prd,
    sDesc: outcome.sd,
    h: outcome.h,
    po: outcome.po,
    key: outcome.key,
    opId: outcome.opId,
    keyDimension: outcome.keyDimension,
  };
  if (outcome.op) {
    o.po = outcome.op;
  }
  return o;
};

export default {};
