import { Switch, Route, Redirect } from 'react-router-dom';
import { ContentLayout } from 'bv-components';
import AppRedux from '../components/app_redux';

const InPlayOverviewComponent = () => (
  <ContentLayout>
    <Switch>
      <Route exact path={['/live', '/live/sport/:sport_agp_id']}>
        <Redirect to="/live/overview" />
      </Route>
      <Route exact path={['/live/group', '/live/group/:sport_id']}>
        <AppRedux treeType="in_play" isGroup />
      </Route>
      <Route exact path={['/live/group/upcoming', '/live/group/:sport_id/upcoming']}>
        <AppRedux treeType="upcoming" isGroup />
      </Route>
      <Route exact path={['/live/overview/upcoming', '/live/:sport_id/upcoming', '/live/upcoming']}>
        <AppRedux treeType="upcoming" />
      </Route>
      <Route exact path={['/live/overview', '/live/:sport_id', '/live/streamable']}>
        <AppRedux treeType="in_play" />
      </Route>
    </Switch>
  </ContentLayout>
);

export default InPlayOverviewComponent;
