import PropTypes from 'prop-types';

import Event from 'InPlayOverview/components/coupons/event';
import Market from 'InPlayOverview/components/coupons/market';

export const TemplateDComponent = (props) => props.events.map(
  (e) => <Event key={e} eventId={e} template="template_d" marketComponent={Market} />,
);

TemplateDComponent.propTypes = {
  events: PropTypes.instanceOf(Object).isRequired,
};

export default TemplateDComponent;
