import CompetitionContext from 'InPlayOverview/services/competition_context';

export const withCompetitionContext = (Component) => (
  (props) => (
    <CompetitionContext.Consumer>
      {({
        marketType, period, sport, ScoreboardComponent, competition,
      }) => (
        <Component
          marketType={marketType}
          period={period}
          sport={sport}
          ScoreboardComponent={ScoreboardComponent}
          competition={competition}
          {...props}
        />
      )}
    </CompetitionContext.Consumer>
  )
);

export default {};
