import PropTypes from 'prop-types';
import { ErrorBoundary } from 'bv-error-boundary';
import { isMarketSuspended } from 'sportsbook-helpers';
import marketPicker from 'InPlayOverview/services/market_picker';
import { withCompetitionContext } from 'InPlayOverview/helpers/competition';

import Outcomes from './outcomes';
import SeeMoreMarkets from './see_more_markets';
import SuspendedMarket from './suspended_market';

export const MarketComponent = ({
  markets, marketType, period, sport, eventId,
}) => {
  if (!markets.length) {
    return <SeeMoreMarkets />;
  }

  const market = marketPicker(markets, marketType, period);
  if (market) {
    if (isMarketSuspended(market)) {
      return (
        <SuspendedMarket
          sportId={sport.id}
          eventId={eventId}
        />
      );
    }

    return (
      <ErrorBoundary FallbackComponent={SeeMoreMarkets}>
        <Outcomes market={market} />
      </ErrorBoundary>
    );
  }
  return (
    <SeeMoreMarkets />
  );
};

MarketComponent.propTypes = {
  markets: PropTypes.instanceOf(Object).isRequired,
  eventId: PropTypes.number.isRequired,
  marketType: PropTypes.instanceOf(Object).isRequired,
  period: PropTypes.instanceOf(Object).isRequired,
  sport: PropTypes.instanceOf(Object).isRequired,
};

export default withCompetitionContext(MarketComponent);
