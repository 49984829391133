import { values } from 'underscore';
import { getJSON } from 'bv-fetch';
import { presentMarket, presentOutcome } from 'InPlayOverview/helpers/presenters';
import { presentSlider } from '../presenters';

const emptyResponse = () => ({
  pgatePath: null,
  slider_elements: [],
  sports: [],
  total_number_of_events: 0,
  total_number_of_streamable_events: 0,
});

export const presentMarkets = (response) => {
  const outcomes = {};
  const markets = {};

  (response || []).forEach((m) => {
    const market = presentMarket(m);
    market.o = (market.o || []).map((o) => {
      const outcome = presentOutcome(o);
      outcomes[outcome.id] = outcome;
      return outcome.id;
    });
    markets[market.id] = market;
  });

  return {
    markets,
    outcomes,
  };
};

export const presentSports = (response) => {
  const events = {};
  const markets = {};
  const outcomes = {};
  const scoreboards = {};

  response.sports = (response.sports || []).map((s) => {
    let numberOfEvents = 0;
    const sport = {
      ...s,
      comp: (s.comp || []).map((c) => ({
        ...c,
        events: (c.events || []).map((e) => {
          e.markets = (e.markets || []).map((m) => {
            const market = presentMarket(m);
            market.o = (market.o || []).map((o) => {
              const outcome = presentOutcome(o);
              outcomes[outcome.id] = outcome;
              return outcome.id;
            });
            markets[market.id] = market;
            return market.id;
          });
          scoreboards[e.id] = e.scoreboard || {};
          events[e.id] = e;
          numberOfEvents += 1;
          delete e.scoreboard;
          return e.id;
        }),
      })),
    };

    sport.numberOfEvents = numberOfEvents;

    if (sport.marketTypes.length) {
      const [marketType] = sport.marketTypes;
      const [period] = marketType.periods;
      sport.selectedMarketType = marketType;
      sport.selectedPeriod = period;
    } else {
      sport.selectedMarketType = {
        headers: [], couponName: '', desc: '', periods: [], marketTypeDimensions: [],
      };
      sport.selectedPeriod = {
        pIds: [], desc: '', long_desc: '', config: { filter: '' }, periodDimensions: [],
      };
    }
    return sport;
  });

  (values(events) || []).forEach((e) => {
    e.visible = false;
  });

  response.events = events;
  response.markets = markets;
  response.outcomes = outcomes;
  response.scoreboards = scoreboards;

  return response;
};

export const fetchSports = ({
  treeType = 'in_play',
  sportId = 'overview',
  isGroup = false,
  widget = false,
  oldSports = [],
  locale = null,
  brandId = null,
  version = null,
} = {}) => {
  let url = '';
  if (widget && !isGroup) {
    url = new URL(`/bv_in_play/v3/${locale}/${brandId}/mini_inplay`, window.location.origin);
  } else if (isGroup) {
    url = new URL(`/bv_in_play/v3/${locale}/${brandId}/${treeType}/sport_groups/${sportId}`, window.location.origin);
  } else {
    url = new URL(`/bv_in_play/v3/${locale}/${brandId}/${treeType}/${sportId}`, window.location.origin);
  }

  let sportsToFetchWithMarkets = [];
  if (sportId === 'overview' && oldSports[0]) {
    sportsToFetchWithMarkets.push(oldSports[0]);
  }
  if (sportId === 'streamable' || sportId === 'mini_inplay' || isGroup) {
    sportsToFetchWithMarkets = oldSports.filter((s) => s.marketTypes.length > 0);
  }
  if (!Number.isNaN(Number(sportId))) {
    const oldSport = oldSports.find((s) => s.id === sportId);

    if (oldSport) {
      sportsToFetchWithMarkets.push(oldSport);
    }
  }

  sportsToFetchWithMarkets.forEach((sport) => {
    if (
      !sport.selectedMarketType.marketTypeDimensions
      || !sport.selectedPeriod.periodDimensions.length
    ) {
      return;
    }
    url.searchParams.append(`marketType[${sport.id}]`, sport.selectedMarketType.id);
    url.searchParams.append(`period[${sport.id}]`, sport.selectedPeriod.id);
  });

  // version is pushed with eventTransition messages
  if (version) {
    url.searchParams.append('version', version);
  }

  // single sport mini-inplay widgets fetch with sport_id query params
  if (widget && !Number.isNaN(Number(sportId))) {
    url.searchParams.append('sport_id', sportId);
  }

  return getJSON(url).catch(emptyResponse)
    .then(presentSlider)
    .then(presentSports);
};

export const fetchMarkets = ({
  sportId, eventIds, marketType, period, locale, brandId,
}) => {
  const url = new URL(`/bv_in_play/v3/${locale}/${brandId}/markets`, window.location.origin);
  url.searchParams.append('sportId', sportId);
  url.searchParams.append('eventIds', eventIds.join(','));
  url.searchParams.append('marketType', marketType);
  if (period) {
    url.searchParams.append('period', period);
  }

  return getJSON(url).catch(emptyResponse).then(presentMarkets);
};
