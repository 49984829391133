import PropTypes from 'prop-types';
import { useFetch } from 'bv-hooks';
import { getSportByEventPathId } from 'bv-services/sports';
import AppRedux from '../components/app_redux.jsx';

const InPlayWidget = ({ sportId }) => {
  const [sport, fetching] = useFetch(() => (
    getSportByEventPathId(sportId)
  ), [sportId]);

  if (fetching) return null;

  const selectedSportId = (sport && sport.group) ? sport.group_id : sportId;
  const isGroup = sport ? !!sport.group : false;

  return <AppRedux widgetSport={selectedSportId} isGroup={isGroup} />;
};

InPlayWidget.propTypes = {
  sportId: PropTypes.number.isRequired,
};

export default InPlayWidget;
